import { makeAutoObservable } from "mobx"

import MatrixStore from "@components/ui/Spreadsheet/state/MatrixStore"
import {
  CellValidationRule,
  MatrixSnapshot,
} from "@components/ui/Spreadsheet/types"
import { createMatrixFromSnapshot } from "@components/ui/Spreadsheet/createMatrix"

import UnifiedMatrixController from "./unified-matrix.controller"

export default class EditUnifiedMatrixStore {
  controller: UnifiedMatrixController

  instance: MatrixStore | null

  isLoading: boolean = false

  error: string | null = null

  constructor(injections: { controller: UnifiedMatrixController }) {
    this.controller = injections.controller
    this.instance = null

    makeAutoObservable(this)
  }

  initNewInstance = async (snapshot?: MatrixSnapshot | null) => {
    try {
      this.isLoading = true
      this.error = null

      const validationRules: Record<string, CellValidationRule> = {
        DROPDOWN_FILE: {
          type: "AUTOCOMPLETE",
          search: this.controller.fetchFiles,
        },
        DROPDOWN_PRODUCT: {
          type: "AUTOCOMPLETE",
          search: this.controller.fetchProducts,
        },
        DROPDOWN_ACCOUNT: {
          type: "AUTOCOMPLETE",
          search: this.controller.fetchAccounts,
        },
      }

      const newInstance = createMatrixFromSnapshot({
        validationRules,
        snapshot,
      })

      this.instance = newInstance

      return newInstance
    } catch (error) {
      this.error = "Initialization failed"
    }

    return null
  }

  getMatrixInstance = (): MatrixStore | null => {
    return this.instance ?? null
  }
}
