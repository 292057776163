import { makeAutoObservable, onBecomeUnobserved, Lambda } from "mobx"
import uniq from "lodash/uniq"

import productsService from "@services/products.service"
import { CompanyTheme } from "@framework/types/company"
import { FailResponse, SuccessResponse } from "@framework/types/common"
import solutionsService from "@services/solutions.service"
import defaultErrorHandler, { errorToText } from "@store/utils/error-handlers"
import { delay } from "@utils/promise"

import UnifiedMatrixStore from "./unified-matrix.store"
import WorkbookReportsStore from "./workbook-reports.store"

export default class UnifiedMatrixController {
  matrix: UnifiedMatrixStore

  workbookReports: WorkbookReportsStore

  constructor(injections: {
    unifiedMatrixStore: UnifiedMatrixStore
    workbookReportsStore: WorkbookReportsStore
  }) {
    this.matrix = injections.unifiedMatrixStore
    this.workbookReports = injections.workbookReportsStore

    makeAutoObservable(this)
  }

  filesAbortController: AbortController | null = null

  fetchFiles = async (query: string): Promise<string[]> => {
    const store = this.matrix.filesStore

    try {
      if (!this.filesAbortController?.signal.aborted)
        this.filesAbortController?.abort()

      this.filesAbortController = new AbortController()

      store.isLoading = true

      const response = await productsService.getTargetFiles(
        query,
        this.filesAbortController.signal
      )

      store.setData(response.data.data)

      return uniq(response.data.data.map((it) => it.name))
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return []
  }

  fetchProducts = async (query: string): Promise<string[]> => {
    const store = this.matrix.productsStore

    try {
      if (!this.filesAbortController?.signal.aborted)
        this.filesAbortController?.abort()

      this.filesAbortController = new AbortController()

      store.isLoading = true

      const response = await solutionsService.getFilters(
        {
          filters: [
            {
              type: "product",
              search: query,
              pageSize: 100,
            },
          ],
        },
        undefined,
        this.filesAbortController.signal
      )

      const items = response.data?.product?.products?.items ?? []

      store.setData(items)

      return items.map((it) => it.name)
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return []
  }

  fetchAccounts = async (query: string): Promise<string[]> => {
    const store = this.matrix.accountsStore

    try {
      if (!this.filesAbortController?.signal.aborted)
        this.filesAbortController?.abort()

      this.filesAbortController = new AbortController()

      store.isLoading = true

      const response = await solutionsService.getSalesforceAccounts(
        {
          query,
          pageNum: 1,
          pageSize: 100,
        },
        this.filesAbortController.signal
      )

      const items = response.data.data ?? []

      store.setData(items)

      return items.map((it) => it.name)
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return []
  }

  generateThemes = async (
    companyName: string,
    category: string,
    businessUnit?: string
  ): Promise<SuccessResponse<string> | FailResponse> => {
    try {
      const response = await productsService.generateThemes({
        companyName,
        category,
        businessUnit,
      })

      const themes = response.data.data

      return {
        status: "SUCCESS",
        data: themes.length ? makeThemesReport(themes) : "-",
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: errorToText(error),
      }
    }
  }

  generateAccountPlan = async (
    targetCompanyUrl: string,
    myCompanyUrl: string,
    targetCompanyBu?: string,
    additionalContext?: string
  ): Promise<SuccessResponse<{ id: string; url: string }> | FailResponse> => {
    try {
      const response = await productsService.generateAccountPlan({
        targetCompanyUrl,
        myCompanyUrl,
        targetCompanyBu,
        additionalContext,
      })

      const reportId = response.data.split("/").at(-1)

      if (!reportId) throw new Error("Failed to extract report ID")

      return {
        status: "SUCCESS",
        data: { id: reportId, url: response.data },
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: errorToText(error),
      }
    }
  }

  generateProductRecommendationReport = async (
    context: string
  ): Promise<SuccessResponse<{ id: string; url: string }> | FailResponse> => {
    try {
      const response =
        await productsService.generateProductRecommendationReport(context)

      const reportId = response.data.split("/").at(-1)

      if (!reportId) throw new Error("Failed to extract report ID")

      return {
        status: "SUCCESS",
        data: { id: reportId, url: response.data },
      }
    } catch (e) {
      return {
        status: "FAILED",
        message: errorToText(e),
      }
    }
  }

  generateUpCrossSellOpportunitiesReport = async (
    account: string
  ): Promise<SuccessResponse<{ id: string; url: string }> | FailResponse> => {
    try {
      const response =
        await solutionsService.generateUpCrossSellOpportunitiesReport(account)

      const reportId = response.data.split("/").at(-1)

      if (!reportId) throw new Error("Failed to extract report ID")

      return {
        status: "SUCCESS",
        data: { id: reportId, url: response.data },
      }
    } catch (e) {
      return {
        status: "FAILED",
        message: errorToText(e),
      }
    }
  }

  generateProductComparisonReport = async (
    productNames: string[]
  ): Promise<SuccessResponse<{ id: string; url: string }> | FailResponse> => {
    try {
      const response = await productsService.generateProductComparisonReport(
        productNames
      )

      const reportId = response.data.split("/").at(-1)

      if (!reportId) throw new Error("Failed to extract report ID")

      return {
        status: "SUCCESS",
        data: { id: reportId, url: response.data },
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: errorToText(error),
      }
    }
  }

  generateDocumentComplianceReport = async (
    authorityDocument: string,
    regulatedDocument: string
  ): Promise<SuccessResponse<{ id: string; url: string }> | FailResponse> => {
    try {
      const response = await productsService.generateDocumentComplianceReport({
        authorityDocument,
        regulatedDocument,
      })

      const reportId = response.data.split("/").at(-1)

      if (!reportId) throw new Error("Failed to extract report ID")

      return {
        status: "SUCCESS",
        data: { id: reportId, url: response.data },
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: errorToText(error),
      }
    }
  }

  generateProductPositioningReport = async (
    application: string,
    targetProduct: string,
    alternateProducts: string[]
  ): Promise<SuccessResponse<{ id: string; url: string }> | FailResponse> => {
    try {
      const response = await productsService.generateProductPositioningReport({
        application,
        targetProduct,
        alternateProducts,
      })

      const reportId = response.data.split("/").at(-1)

      if (!reportId) throw new Error("Failed to extract report ID")

      return {
        status: "SUCCESS",
        data: { id: reportId, url: response.data },
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: errorToText(error),
      }
    }
  }

  tasks = new Map<string, Lambda>()

  pullReport = async (reportId: string) => {
    if (this.tasks.has(reportId)) return null

    try {
      const store = this.workbookReports.getById(reportId)

      if (store == null) throw new Error("Unexpected error")

      const deleteTask = () => {
        const callback = this.tasks.get(reportId)
        this.tasks.delete(reportId)
        callback?.()
      }

      const callback = onBecomeUnobserved(store, "status", deleteTask)

      this.tasks.set(reportId, callback)

      while (this.tasks.has(reportId)) {
        // eslint-disable-next-line no-await-in-loop
        await this.loadReport(reportId)

        if (store.status !== "in-progress") break

        // eslint-disable-next-line no-await-in-loop
        await delay(5_000, null)
      }

      deleteTask()
    } catch (e) {
      return defaultErrorHandler(e)
    }

    return null
  }

  loadReport = async (reportId: string) => {
    const store = this.workbookReports.getById(reportId)

    if (store == null) throw new Error("Unexpected error")

    try {
      if (store.isLoading) return

      store.isLoading = true
      store.error = null

      const response = await productsService.getWorkbookReport(reportId)

      store.update(response.data)
    } catch (error) {
      store.error = defaultErrorHandler(error)
      store.status = "failed"
    } finally {
      store.isLoading = false
    }
  }
}

const makeThemesReport = (themes: CompanyTheme[]) => {
  return themes.map((it) => `- ${it.content}`).join("\n")
}
