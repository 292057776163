import { AxiosResponse } from "axios"
import { Observable } from "rxjs"

import { AccountOption, SolutionEntity } from "@framework/types/solution"
import { GetFilterRequest, QueryFilter } from "@framework/types/query-filter"
import { SolutionPermission } from "@framework/types/user"
import { ListResponse } from "@framework/types/product"
import { PaginationParams } from "@framework/types/utils"

import { AvatarDetailsData } from "../framework/types/avatar"
import { DefaultSuccessResponse } from "./common/types"
import HttpService from "./http.service"

export interface GetSolutionsResponse extends DefaultSuccessResponse {
  data: AvatarDetailsData
}

export interface GetProductSolutionListResponse {
  data: SolutionEntity[]
}

export interface GetProductSolutionResponse {
  data: SolutionEntity
}

export interface GetFiltersResponse extends DefaultSuccessResponse {
  data: QueryFilter
}

class SolutionAPI extends HttpService {
  getSolutions = (
    channelId: string
  ): Promise<AxiosResponse<GetSolutionsResponse>> =>
    this.get("ts/channel", true, { channelId })

  getAllProductSolutions = (): Promise<
    AxiosResponse<GetProductSolutionListResponse>
  > => this.get("ts/admin/product-solution/all")

  getFilters = (
    body: GetFilterRequest,
    companyId?: string,
    signal?: AbortSignal
  ): Promise<AxiosResponse<QueryFilter>> =>
    this.post(`ts/hub/filters`, body, true, {
      signal,
      params: { companyId },
    })

  getFilters$ = (
    body: GetFilterRequest,
    companyId?: string
  ): Observable<AxiosResponse<QueryFilter>> =>
    this.postStream$(`ts/hub/filters`, body, {
      params: { companyId },
    })

  createProductSolution = (
    data: any
  ): Promise<AxiosResponse<GetProductSolutionResponse>> =>
    this.post("ts/admin/product-solution", data)

  updateProductSolution = (
    id: string,
    data: any
  ): Promise<AxiosResponse<GetProductSolutionResponse>> =>
    this.patch(`ts/admin/product-solution/${id}`, data)

  removeProductSolution = (
    productSolutionId: string
  ): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.delete(`ts/admin/product-solution`, null, true, { productSolutionId })

  getSolutionPermissions = (
    solutionId: string
  ): Promise<AxiosResponse<SolutionPermission[]>> =>
    this.get(`ts/admin/product-solutions/${solutionId}/permissions`, true)

  updateSolutionPermissions = (
    params: {
      solutionId: string
    } & (
      | {
          addEntities: { entityId: string; entityType: string }[]
          removeEntities: { entityId: string; entityType: string }[]
          allowAllEntities: false
        }
      | {
          allowAllEntities: true
        }
    )
  ): Promise<AxiosResponse<{ data: string }>> =>
    this.put(
      `ts/admin/product-solutions/${params.solutionId}/permissions`,
      params,
      true
    )

  generateUpCrossSellOpportunitiesReport = (
    accountName: string
  ): Promise<AxiosResponse<string>> => {
    return this.post("ts/companies/cross-sell-up-sell", { accountName })
  }

  getSalesforceAccounts = (
    request: PaginationParams,
    signal?: AbortSignal
  ): Promise<AxiosResponse<ListResponse<AccountOption>>> =>
    this.get("ts/companies/salesforce-accounts", true, request, null, signal)
}

export default new SolutionAPI()
