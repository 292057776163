/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React from "react"

import Avatar, { AvatarProps } from "@components/ui/Avatar/Avatar"

import Text from "../Typography/Text"

import styles from "./UserCard.module.sass"

export interface UserCardProps {
  fullName: string
  status?: React.ReactNode
  metaInfo?: React.ReactNode
  other?: React.ReactNode
  avatarSrc?: string | null
  className?: string
  align?: "center" | "start" | "end"
  variant?: "card" | "default"
  isAddedEarlier?: boolean
  onClick?: () => void
  avatarProps?: AvatarProps
}

export const UserCard: React.FC<UserCardProps> = ({
  fullName,
  status,
  metaInfo,
  other,
  avatarSrc = "",
  className,
  variant = "content",
  align = "start",
  isAddedEarlier = false,
  onClick,
  avatarProps,
}) => (
  <div
    className={clsx(
      styles.root,
      { [styles.clickable]: !!onClick },
      styles[`variant-${variant}`],
      className
    )}
    onClick={onClick}
  >
    <div className={clsx(styles.baseContainer, [styles[`align-${align}`]])}>
      <Avatar
        className={styles.avatar}
        name={fullName}
        src={avatarSrc}
        {...avatarProps}
      />
      <div className={styles.body}>
        <div className={styles.label}>
          <Text variant="h5" className={styles.label}>
            {fullName || "Deactivated user"}
          </Text>

          {status}
        </div>

        {metaInfo != null && (
          <Text variant="body2" color="text50Color">
            {metaInfo}
          </Text>
        )}

        {other != null && <div className={styles.other}>{other}</div>}
      </div>
    </div>

    <div className={styles.baseContainer}>
      {isAddedEarlier && <p className={styles.addedEarlier}>Added earlier</p>}
    </div>
  </div>
)

export default UserCard
